import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <a href="https://201lab.com">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Private E-Commerce Apps -- 201 Lab&copy; {new Date().getFullYear()}
          </p>
        </header>
      </a>
    </div>
  );
}

export default App;
